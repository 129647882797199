/* Variáveis CSS */
:root {
  --primary-color: #f04b23;
  --secondary-color: #292c37;
  --white-color: #ffffff;
  --black-color: #000000;
  --gray-color: #f8f4f2;
  --section-padding: 60px 20px;
  --section-height: 80vh;
  --card-height: 350px;
}

/* Estilos Gerais */
.games-page {
  font-family: "Red Hat Display", sans-serif;
  margin: 0;
  padding: 0;
}
