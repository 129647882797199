/* Seção Quem Somos */
.who-we-are-section {
  background-color: #efefed;
  padding: 80px 0;
  font-family: "Red Hat Display", sans-serif;
}

.who-we-are-container {
  margin-top: 80px;
}

.who-we-are-logo img {
  max-width: 230px;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.who-we-are-logo img:hover {
  transform: scale(1.05);
}

.who-we-are-text-card {
  background-color: #ffffff;
  color: #393645;
  padding: 60px;
  border-radius: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  min-height: 500px;
  transition: transform 0.3s ease;
}

.who-we-are-text-card:hover {
  transform: scale(1.01);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.433);
}

.who-we-are-text-card h3 {
  font-family: "Red Hat Display";
  font-size: 40px;
  font-weight: 400;
  line-height: 47.68px;
  text-align: left;
}

.who-we-are-text-card h3 strong {
  font-weight: 800;
}

.who-we-are-text-card p {
  margin-top: 20px;
  font-family: "Red Hat Display";
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .who-we-are-section {
    padding: 20px;
  }

  .who-we-are-logo-container {
    position: relative;
  }

  .who-we-are-logo {
    position: absolute;
    top: -40px;
    right: -40px;
    z-index: 1;
    max-width: 80px;
  }

  .who-we-are-text-card {
    padding: 20px;
    min-height: auto;
    position: relative;
  }

  .who-we-are-text-card h3 {
    font-size: 1.5rem;
  }

  .who-we-are-text-card p {
    font-size: 0.9rem;
    line-height: 24px;
  }
}

/* Responsividade para Tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .who-we-are-section {
    padding: 40px 0;
  }

  .who-we-are-logo img {
    max-width: 150px;
  }

  .who-we-are-text-card {
    padding: 40px;
    min-height: 400px;
  }

  .who-we-are-text-card h3 {
    font-size: 32px;
  }

  .who-we-are-text-card p {
    font-size: 18px;
    line-height: 30px;
  }
}
