.download-section {
  background-color: #efefed;
  padding: 50px 0;
}

.download-item {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 350px; /* Diminuído para garantir proporção adequada */
  border-radius: 8px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px;
  color: #393645;
  overflow: hidden;
}

.download-content {
  position: relative;
  text-align: center;
}

.download-content h3 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 28px; /* Tamanho do título reduzido para proporcionalidade */
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 10px;
}

.download-content p {
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px; /* Tamanho do texto reduzido */
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 15px;
}

.download-button {
  display: inline-block;
  padding: 10px 20px; /* Padding ajustado */
  background-color: #393645;
  color: #ffffff;
  text-decoration: none;
  border-radius: 16px;
  font-size: 14px; /* Tamanho de fonte reduzido */
  text-align: center;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #333333;
}

.download-button i {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .download-item {
    max-width: 100%;
    background-image: url("/public/assets/download1.png") !important;
  }

  .download-content h3 {
    font-size: 24px; /* Reduzido para melhor ajuste no mobile */
  }

  .download-content p {
    font-size: 14px; /* Reduzido para melhor ajuste no mobile */
  }

  .download-button {
    font-size: 12px; /* Reduzido para melhor ajuste no mobile */
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .download-item {
    min-width: 100%;
    margin-bottom: 20px; /* Espaçamento entre os itens no tablet */
  }

  .download-content h3 {
    font-size: 26px; /* Ajuste do título no tablet */
  }

  .download-content p {
    font-size: 16px; /* Ajuste do texto no tablet */
  }
}
