/* src/pages/DetailsPage.css */

/* Variáveis CSS */
:root {
  --primary-color: #f04b23;
  --secondary-color: #292c37;
  --white-color: #ffffff;
  --black-color: #000000;
  --gray-color: #f8f4f2;
  --section-padding: 60px 20px;
  --section-height: 80vh;
  --card-height: 350px;
}

/* Estilos Gerais */
.details-page {
  font-family: "Red Hat Display", sans-serif;
  margin: 0;
  padding: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .who-we-are .content-box,
  .launch-content {
    flex-direction: column;
    text-align: center;
  }

  .launch-logo img {
    width: 80%;
    margin: 20px auto;
  }

  .partner-logo {
    max-width: 120px;
  }
}

@media (max-width: 576px) {
  .first-section,
  .launch-section {
    padding: 30px 10px;
  }

  .first-title,
  .launch-title {
    font-size: 1.8rem;
  }

  .who-we-are .content-box,
  .launch-content {
    padding: 30px;
  }

  .launch-logo img {
    width: 100%;
  }
}
