.feature-section {
  display: flex;
  flex-direction: column;
}

.feature-item {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  width: 100vw;
}

.feature-inner {
  max-width: 1000px;
  margin: 0 auto;
}

.feature-image {
  max-width: 150px;
  height: auto;
  margin-right: 10px;
  margin-bottom: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.feature-section h3 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 36px;
  font-weight: 900;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 10px;
}

.feature-section p {
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .feature-content {
    text-align: left;
  }

  .feature-image {
    max-width: 200px;
  }

  .feature-section h3 {
    font-size: 48px;
  }

  .feature-section p {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .feature-item {
    padding: 20px 0;
    background-position: 25% center;
  }

  .feature-inner {
    padding: 0 15px;
  }

  .feature-image {
    max-width: 120px;
    height: auto;
  }

  .feature-section h3 {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  .feature-section p {
    font-size: 14px;
    line-height: 1.3;
  }

  .feature-item .row {
    flex-direction: row;
    align-items: center;
  }

  .feature-item .col-12.col-md-4 {
    flex: 0 0 auto;
  }

  .feature-item .col-12.col-md-8 {
    flex: 1;
  }
}

/* Efeito hover para feature-image */
.feature-image:hover {
  transform: scale(1.3);
  opacity: 0.95;
}
