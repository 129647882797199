/* Seção do Refúgio */
.refugio-section {
  background-color: #efefed;
  padding-top: 100px;
  font-family: "Red Hat Display", sans-serif;
  overflow: hidden;
}

.refugio-image-wrapper {
  width: 100%;
}

.refugio-banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  animation: fadeIn 1s ease-in-out;
}

.refugio-content {
  text-align: center;
  margin: 40px auto;
  color: #393645;
}

.refugio-content h3 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 64px;
  font-weight: 900;
  line-height: 1.1;
  padding-bottom: 30px;
  color: #393645;
  transition: color 0.3s ease;
}

.refugio-content p {
  font-family: "Red Hat Display", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  color: #393645;
  transition: color 0.3s ease;
}

.refugio-content:hover h3,
.refugio-content:hover p {
  color: #000000;
}

/* Estilo da imagem do Google Play Badge */
.google-play-badge {
  margin-top: 20px;
  width: 200px;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.google-play-badge:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Animação de FadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsividade para telas menores */
@media (max-width: 1200px) {
  .refugio-content h3 {
    font-size: 56px;
    line-height: 1.2;
  }

  .refugio-content p {
    font-size: 22px;
    line-height: 34px;
  }
}

@media (max-width: 992px) {
  .refugio-content h3 {
    font-size: 48px;
    line-height: 1.2;
  }

  .refugio-content p {
    font-size: 20px;
    line-height: 32px;
  }
}

@media (max-width: 768px) {
  .refugio-content {
    padding: 0 30px 30px 30px;
  }
  .refugio-content h3 {
    text-align: left;
    font-size: 40px;
    line-height: 1.3;
  }

  .refugio-content p {
    text-align: left;
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .refugio-content h3 {
    font-size: 32px;
    line-height: 1.4;
  }

  .refugio-content p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 400px) {
  .refugio-content h3 {
    font-size: 28px;
    line-height: 1.3;
  }

  .refugio-content p {
    font-size: 14px;
    line-height: 22px;
  }
}
