.footer {
  background-color: #393645;
  color: white;
  font-size: 15px;
  font-family: "Red Hat Display", sans-serif;
  padding: 40px 0;
}

.footer-container {
  max-width: 60%;
  margin: 0 auto;
}

.logo-container {
  text-align: left;
  margin-bottom: 20px;
}

.footer-logo {
  max-width: 150px;
}

.footer-links {
  margin-top: 30px;
}

.social-icons {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 15px;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.footer-menu {
  list-style: none;
  padding: 0;
  text-align: left;
}

.footer-menu li {
  margin-bottom: 5px;
}

.footer-menu li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-menu li a:hover {
  color: #fba42b;
  transform: translateX(5px);
}

footer p {
  margin: 0;
  text-align: left;
  font-size: 15px;
}

footer a {
  color: white;
  text-decoration: none;
  font-size: 15px;
}

footer h5 {
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  white-space: nowrap;
}

.footer-rights {
  margin-top: 30px;
  text-align: left;
  font-size: 0.8rem;
}

/* Estilos para telas de tamanho médio (entre 768px e 991px) */
@media (min-width: 768px) and (max-width: 1200px) {
  .footer-container {
    max-width: 80%;
  }

  .logo-container {
    text-align: left;
    margin-bottom: 30px;
  }

  .footer-logo {
    max-width: 130px;
    margin: 0 auto;
  }

  .footer-links {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }

  .footer-links h5 {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .footer-menu {
    text-align: left;
    padding: 0 10px;
  }

  .footer-menu li a {
    font-size: 14px;
  }

  .footer-rights {
    margin-top: 20px;
    font-size: 0.9rem;
    text-align: center;
  }

  .social-icons {
    justify-content: flex-start;
    gap: 10px;
  }

  .social-icon {
    width: 22px;
    height: 22px;
  }
}

/* Estilos para Mobile */
@media (max-width: 767px) {
  .footer-container {
    max-width: 100%;
  }

  .footer {
    padding: 0px;
  }

  .footer-logo {
    justify-content: center;
    max-width: 120px;
    margin: 20px 0 0 20px;
  }

  .logo-rights-row .d-flex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .logo-rights-row .footer-rights-text {
    font-size: 0.8rem;
    color: #898989;
    margin: 0 13px -5px 0;
    align-self: flex-end;
  }

  .footer-split {
    border: 0;
    border-top: 1px solid #ffffff;
    margin: 25px 12px;
  }

  .footer-links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 10px;
    padding-bottom: 30px;
  }
  .footer-links > .col {
    text-align: left;
  }

  footer p {
    text-align: left;
    font-size: 14px;
  }

  .footer-links h5 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .footer-menu {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .footer-menu li {
    margin-bottom: 5px;
  }

  .social-icons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .social-icons a {
    display: flex;
    align-items: center;
  }

  .social-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  footer p {
    text-align: left;
  }
}
